import React, { useEffect } from "react";
import "./winnerPopup.css";
import closeIconRed from "../../images/closeIconRed.png";

const WinnerPopup = (props) => {
  const handleClose = (e) => {
    props.togglePopup(0);
    setTimeout(() => {
      document.getElementById("header").scrollIntoView({ behavior: "smooth" });
    }, 300);
  };

  useEffect(() => {
    document
      .getElementById("header")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const successMsg =
    "Molimo Vas da proverite vašu e-mail adresu i verifikujete svoj glas.";

  return (
    <div className="mainPopup">
      <div className="content">
        <div className="title">Pobednica konkursa</div>
        <img
          src="https://api.iframe-umetnost-rs.birramoretti.com/arts/anja-preradovic-web.jpg"
          alt="winner"
          className="picture"
        ></img>
        <div className="name">Anja Preradović</div>
        <div className="infoStyle">L'Italiano vero <br/> Akril na platnu</div>
        <div className="place">Akademija umetnosti, Novi Sad</div>

        <div className="close" onClick={handleClose}>
          <img src={closeIconRed} />
        </div>
      </div>
    </div>
  );
};

export default WinnerPopup;
